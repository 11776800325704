import { useWeb3React } from "@web3-react/core";

export default function Wallet() {
  const wallet = useWeb3React();
  return (
    <div>
      <h1>Wallet</h1>
      <p>Connected Wallet: {wallet.account ? wallet.account : "N/A"}</p>
    </div>
  );
}
