import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { injected, walletconnect, walletlink } from "../wallet/connectors.js";

import Button from "../components/Button";
import metamaskBtnImg from "../images/button/metamask.png";
import walletConnectBtnImg from "../images/button/walletConnect.png";
import coinbaseBtnImg from "../images/button/coinbase.png";


export default function WalletProtected(props) {
  const wallet = useWeb3React();
  const location = useLocation();
  const navigate = useNavigate()


  useEffect(() => {
    if (!wallet.active) {
      navigate("/");
    }
  }, []);


  useEffect(() => {
    if (location.pathname === "/" && wallet.active) {
      navigate("/claim");
    }
  }, [wallet]);

  return (
    <React.Fragment>
      {wallet.account ? (
        props.children
      ) : (
        <React.Fragment >
          <div className="flex flex-col text-[26px] w-full max-w-[600px] items-center justify-center px-0 lg:px-[17%]">
            <img src={metamaskBtnImg} onClick={() => { wallet.activate(injected) }} className="hover:scale-105 cursor-pointer"></img>
            <img src={walletConnectBtnImg} onClick={() => { wallet.activate(walletconnect) }} className="hover:scale-105 cursor-pointer -mt-[20px] lg:-mt-[30px]"></img>
            <img src={coinbaseBtnImg} onClick={() => { wallet.activate(walletlink) }} className="hover:scale-105 cursor-pointer -mt-[20px] lg:-mt-[30px]"></img>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
