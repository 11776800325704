
import { useEffect, useState, useLayoutEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
import { useQuery } from 'react-query';

import * as API from "../utils/utils";
import Web3 from 'web3';
import Button from "../components/Button";
import Tokens from "../components/Tokens";
import NFTs from "../components/NFTs";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import cap from "../images/cap-hoodie-01.png";
import hoodie from "../images/hoodie.png";
import Header from "../components/Header";
import MainPage from "../components/MainPage";
import ProcessingModal from "../components/ProcessingModal.js";

import drugreceiptAbi from '../abis/drugreceiptAbi';
import greetingcardAbi from '../abis/greetingcardAbi';

import logo from "../images/DRx-logo.png";
import claimNowBtnImg from "../images/button/claimNow.png";
// import rex from "../images/Murdered-Out-Cap.png";

const progressMessage = [["THE MAGIC IS HAPPENING...", "Good things come to Snugglers who wait."], ["OH SNUG!", "Looks like you've already claimed your holiday card."], ["SNUG YEAH!", "You've claimed your DRx-mas holiday card."]];

export default function Claim() {

    const wallet = useWeb3React();
    const web3 = new Web3(wallet.library.provider);
    const [status, setStatus] = useState(""); // "success", "error", "processing"
    const [txHash, setTxHash] = useState("");
    const [isConnected, setIsConnected] = useState(false);
    const [isProcessingModalOpen, setIsProcessingModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState(progressMessage[0]);
    const [tokenExist, setTokenExist] = useState(false);
    const [tokensLoading, setTokensLoading] = useState(true);

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    useLayoutEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const checkWallet = async () => {
            if (wallet.active && wallet.account != undefined) {
                const drugContractAddress = "0x00ea2894fe840f105ab99a8f8f75b1f17e94843a";
                const drugContract = new web3.eth.Contract(drugreceiptAbi, drugContractAddress);
                const _account = await web3.eth.getAccounts();
                // setTokenExist(false);
                drugContract.methods.balanceOf(_account[0]).call().then(balanceOfDrugToken => {
                    // console.log("balanceOfDrugToken", balanceOfDrugToken);
                    if (balanceOfDrugToken > 0) {
                        setTokenExist(true);
                    } else {
                        setTokenExist(false);
                    }
                    // setMinted(true);
                }).catch(err => {
                    console.log(err);
                    setTokenExist(false);
                });
                // console.log("wallet!!!!!!!!", wallet.account);
            }
        };
        checkWallet();
    }, [wallet]);

    const navigate = useNavigate();


    const doClaim = async () => {
        setIsProcessingModalOpen(true);
        setMessage(progressMessage[0]);

        const GreetingcardAddress = "0x9789353350aCC2583C2f1786955eE5057DBAd01C";

        const greetingcardContract = new web3.eth.Contract(greetingcardAbi, GreetingcardAddress);

        const _account = await web3.eth.getAccounts();

        let data = {
            walletAddress: _account[0]
        }
        // console.log("###########", data);
        let res = await API.checkClaimed(data);
        if (res.ok) {
            res = await res.json();
            // console.log("value",res);
            if (res) {
                setIsProcessingModalOpen(true);
                setMessage(progressMessage[1]);
            } else {
                // await API.setClaimedToken(data);
                greetingcardContract.methods.getClaimStatus(0, _account[0]).call().then(mintStatus => {
                    // console.log("mintStatus", mintStatus);
                    if (!mintStatus) {
                        greetingcardContract.methods
                            .normalMint()
                            .send({ from: _account[0] })
                            .then(async (tx) => {
                                // console.log("Tx", tx);
                                let res1 = await API.setClaimedToken(data);
                                setMessage(progressMessage[2]);
                            }).catch(err => {
                                console.log(err);
                                setIsProcessingModalOpen(false);
                            });
                    } else {
                        setIsProcessingModalOpen(true);
                        setMessage(progressMessage[1]);
                    }
                }).catch(err => {
                    console.log(err);
                    setIsProcessingModalOpen(false);
                });
            }
            // NotificationManager.success("Outta here! Team successfully deleted.");
        } else {
            res = await res.json();
            console.log(res);
        }

        // greetingcardContract.methods
        //     .mint()
        //     .send({ from: _account[0]})
        //     .then(tx => {
        //         console.log("Tx", tx);
        //         setMessage(progressMessage[2]);
        //     }).catch(err => {
        //         console.log(err);
        //         setIsProcessingModalOpen(false);
        //     });

        //////////////////////////////////////////////////////
        //have to set tokenID
        /////////////////////////////////////////////////////

        // greetingcardContract.methods.getClaimStatus(0, _account[0]).call().then(mintStatus => {
        //     // console.log("mintStatus", mintStatus);
        //     if (!mintStatus) {
        //         greetingcardContract.methods
        //             .normalMint()
        //             .send({ from: _account[0] })
        //             .then(tx => {
        //                 // console.log("Tx", tx);
        //                 setMessage(progressMessage[2]);
        //             }).catch(err => {
        //                 console.log(err);
        //                 setIsProcessingModalOpen(false);
        //             });
        //     } else {
        //         setIsProcessingModalOpen(true);
        //         setMessage(progressMessage[1]);
        //     }
        // }).catch(err => {
        //     console.log(err);
        //     setIsProcessingModalOpen(false);
        // });
    }

    return (
        <div className="h-full w-full relative">
            {isProcessingModalOpen && (
                <ProcessingModal
                    setIsProcessingModalOpen={setIsProcessingModalOpen}
                    message={message}
                />
            )}
            <div className="">
                <div className="sticky top-0 z-[30]">
                    <Header
                        menuItems={[
                            "Main Website",
                            "Opensea Collection",
                            "Drug store"
                        ]}
                        rightArea={null}
                        showSocial={true}
                        externalLink={true}
                        routes={["https://drugreceipts.com/", "https://opensea.io/collection/drugreceipts", "https://drx.store/"]}
                    />
                </div>

                <MainPage>
                    <div className="flex flex-col h-full mx-auto mt-12 sm:pb-12 ">

                        <div className="flex items-center justify-center w-full h-full gap-8 ">
                            <div className="w-full lg:w-[62%] my-10">

                                <img className="w-[182px] my-8 " src={logo} />
                                <div className="font-pressio-condensed text-white text-[94px] leading-[90px] font-medium mt-[54px]">
                                    MERRY DRX-MAS!
                                </div>
                                <div className="mt-[60px]">
                                    <div className="font-open-sans text-white text-[18px] lg:text-[24px] font-light mt-5">
                                        <div>
                                            <p>Claim was closed.</p>
                                        </div>
                                    </div>
                                    {/* <div className="font-open-sans text-white text-[18px] lg:text-[24px] font-light mt-5">
                                        <div className={(tokenExist ? "hidden" : "block")}>
                                            <p>Oh snug! No DRx tokens found.</p>
                                            <p className="underline cursor-pointer"><a href="https://opensea.io/collection/drugreceipts" target="_blank">Become a Drug Snuggler today!</a></p>
                                        </div>
                                    </div>
                                    <div className="flex gap-4 text-[26px] max-w-[350px]">
                                        <div className={(tokenExist ? "block" : "hidden") + " w-full"}>
                                            <img src={claimNowBtnImg} onClick={() => { doClaim() }} className="hover:scale-105 cursor-pointer ml-0 sm:-ml-[18px]"></img>
                                        </div>
                                    </div> */}
                                </div>
                            </div>


                            {dimensions.width > 900 && (
                                <img
                                    src={hoodie}
                                    className="w-[36%] my-10"
                                    alt="Rex Character"
                                    width={470}
                                    height={505}
                                />
                            )}
                        </div>
                    </div>
                </MainPage>
            </div>
        </div>

    )
}