import React from "react";
import Form from "./Form";

import { ReactComponent as Close } from "../images/X.svg";

export default function ProcessingModal({
    setIsProcessingModalOpen,
    message,
}) {

    return (
        <div
            className="fixed flex flex-wrap justify-center items-center pt-[80px]
        top-0 left-0 bottom-0 -right-0 z-10 
        bg-opacity-60 bg-black backdrop-blur-sm w-screen h-full"
        >
            <div
                className="flex flex-col fixed justify-center items-center p-5
            w-[80%] md:w-[50%] lg:w-[80%] xl:w-[60%] max-w-[600px]  bg-drbg bg-cover rounded-3xl overflow-y-auto 2xl:overflow-hidden"
            >
                <div
                    onClick={() => { setIsProcessingModalOpen(false) }}
                    className="flex absolute right-0 top-[0px] md:top-0 p-3 m-2 
                    justify-center items-center h-14 w-14 rounded-full
                    hover:cursor-pointer transform hover:border-4 "
                >
                    <Close className="" />
                </div>
                <div className="text-white h-full justify-center text-center items-center flex flex-col p-4 py-12">
                    <h1 className="font-pressio-condensed text-4xl sm:text-5xl mt-6">
                        {message[0]}
                    </h1>
                    <div className="py-2">
                        <p className="font-open-sans font-normal text-white text-[22px] font-light mt-3">{message[1]}</p>
                    </div>
                    {message[0] == 'SNUG YEAH!' &&
                        <div className="py-1">
                            <a href="https://opensea.io/collection/drugreceipts-drx-greeting-cards" target="_blank" className=" cursor-pointer underline hover:no-underline"><p className="font-open-sans font-normal text-white text-[18px] font-light">View on OpenSea</p></a>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};